import { $ } from "../third-party-imports";
import axios from 'axios';
import { apiConfig, startLoader } from "..";
import { stopLoader } from "..";
import { animation } from '../util/refreshScheduleData';
import { RLPostData } from "../util/rl-post-data";
import { Api_Error_Msg } from "../config/constant";
import { croShortForm } from "../util/cro-short-form";
import { setServiceDataConfirmationText } from "../util/get-service-list";
export function LeadConfirmation()
{
    startLoader();
    let conceptCode = $('#conceptCode').val();
    $("#sd_btn_next").addClass("disabled");
    const objOSGlobal = window.objOSGlobal;
    var fileURL1, fileURL2, fileURL3 = "";
    if (objOSGlobal.requestBodyValues.FileUrls !== undefined)
    {
        fileURL1 = typeof (objOSGlobal.requestBodyValues.FileUrls[0]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[0] : "";
        fileURL2 = typeof (objOSGlobal.requestBodyValues.FileUrls[1]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[1] : "";
        fileURL3 = typeof (objOSGlobal.requestBodyValues.FileUrls[2]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[2] : "";
    }
    window.objOSGlobal.requestBodyValues.GlobalfilesToUpload = [];
    if (objOSGlobal !== undefined)
    {
        const options: any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        if (objOSGlobal.requestBodyValues.IsLeadOnly == true)
        {
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
        }
        else if (objOSGlobal.requestBodyValues.Warranty == true)
        {
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
        }
        else
        {
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date(objOSGlobal.paramsUI.selectedDate).toLocaleDateString("en-US", options);
        }
        if (window.objOSGlobal.requestBodyValues.ConceptId === "17")
        {
            objOSGlobal.requestBodyValues.ServiceText = "Interested in Property Management";
            objOSGlobal.requestBodyValues.DayTimePhoneNumber = objOSGlobal.requestBodyValues.Phone;
            objOSGlobal.requestBodyValues.ZipCode = objOSGlobal.requestBodyValues.PostalCode;

        }
        var host = window.location.hostname.toLowerCase();
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
            objOSGlobal.requestBodyValues.IsTest = true;
        }else {
            objOSGlobal.requestBodyValues.IsTest = false;
        }
        let hrefUrl = window.location.href;
        if(hrefUrl.indexOf('?utm_campaign') > -1) {
            let urlPath = new URL(hrefUrl);
            let urlAfterQueryStr = urlPath.search;
            urlAfterQueryStr = urlPath.search.replace('?','');
            objOSGlobal.requestBodyValues.CampaignName = urlAfterQueryStr;
        } else {
            objOSGlobal.requestBodyValues.CampaignName = 'N/A';
        }
        
        objOSGlobal.requestBodyValues.ReferringURL = document.referrer;
        objOSGlobal.requestBodyValues.CurrentPage = host;
        var serviceTypeID = 0;
        if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman"){
            var businessNameNote = "";
            if(window.objOSGlobal.requestBodyValues.MrhServiceType.toLowerCase() == "residential"){
            serviceTypeID = 1;
            }
            else{
                serviceTypeID = 2;
                businessNameNote = ", \r\n Business Name: " + window.objOSGlobal.requestBodyValues.BusinessName;
            }
        }else if(conceptCode ==='GUY') {
            if(window.objOSGlobal.requestBodyValues.guyServiceType == "residential"){
                serviceTypeID = 1;
            }else {
                serviceTypeID = 2;
            }
        }
        
        if(window.objOSGlobal.requestBodyValues.requestContactForm){
            let formContactType = $("#form_type").val();
            if(formContactType ==="maintenance"){
                window.objOSGlobal.requestBodyValues.Note = '';
                window.objOSGlobal.requestBodyValues.Note = 'Maintenance Plan';
            }else{
                window.objOSGlobal.requestBodyValues.Note = '';
                window.objOSGlobal.requestBodyValues.Note = 'Finance';
            }
        }else{
            if(conceptCode=='MLY'){
                let preferredCommunicationText = sessionStorage.getItem('PreferredCommunicationText');
                let modifier = window.objOSGlobal.requestBodyValues.modifier;
                if(modifier == "Commercial"){
                    objOSGlobal.requestBodyValues.Note ="commercial lead";
                }else{
                    objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note;
                }
                objOSGlobal.requestBodyValues.LeadSourceId = objOSGlobal.requestBodyValues.ConceptCode == 'MLY' ? objOSGlobal.requestBodyValues.NeighborlyLeadSourceId : objOSGlobal.requestBodyValues.LeadSourceId;
                objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = null;
                objOSGlobal.requestBodyValues.Note =  preferredCommunicationText !="" ? objOSGlobal.requestBodyValues.Note + ', Preferred Communication:'+ preferredCommunicationText:objOSGlobal.requestBodyValues.Note;
            }else{
                if(conceptCode === 'GUY'){
                    objOSGlobal.requestBodyValues.Note = $('#inputService').val() ? $('#inputService').val() + ", \r\n "+ objOSGlobal.requestBodyValues.Note : objOSGlobal.requestBodyValues.Note
                }else {
                    objOSGlobal.requestBodyValues.Note = $('#inputService')?.val() ? $('#inputService')?.val() + ", \r\n "+ objOSGlobal.requestBodyValues.Note : objOSGlobal.requestBodyValues.Note
                }
                
                if (objOSGlobal.requestBodyValues.Note.includes('undefined') || window.objOSGlobal.requestBodyValues.Note == ""){
                        if ($("#brandName").val().toLowerCase() === 'mr-handyman' || $("#brandName").val().toLowerCase() === "my-handyman"){
                            objOSGlobal.requestBodyValues.Note = $("#inputHelp").val();
                            var commaVal = objOSGlobal.requestBodyValues.Note == "" ? "" : ", ";
                            objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note + commaVal + "\r\n Service Type: " + window.objOSGlobal.requestBodyValues.MrhServiceType + businessNameNote;
    
                            if (window.objOSGlobal.requestBodyValues.Note != ' ')
                            {
                                window.objOSGlobal.paramsUI.serviceDetailsSelectedText = window.objOSGlobal.paramsUI.serviceDetailsSelectedText != '' && window.objOSGlobal.paramsUI.serviceDetailsSelectedText != undefined ? ", " + window.objOSGlobal.paramsUI.serviceDetailsSelectedText : '';
                                window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                            } else
                            {
                                window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                            }
                        }
                        else {
                            if (window.objOSGlobal.requestBodyValues.Note != ' ') {
                                window.objOSGlobal.paramsUI.serviceDetailsSelectedText = window.objOSGlobal.paramsUI.serviceDetailsSelectedText != '' && window.objOSGlobal.paramsUI.serviceDetailsSelectedText != undefined ? ", " + window.objOSGlobal.paramsUI.serviceDetailsSelectedText : '';
                                window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                            } else {
                                window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                            }
                        }
                    }
                
                    if ($("#brandName").val().toLowerCase() !== 'mr-handyman' && $("#brandName").val().toLowerCase() !== "my-handyman")
                    {
                        if (window.objOSGlobal.requestBodyValues.Note != ' ')
                        {
                            window.objOSGlobal.paramsUI.serviceDetailsSelectedText = window.objOSGlobal.paramsUI.serviceDetailsSelectedText != '' ? ", " + window.objOSGlobal.paramsUI.serviceDetailsSelectedText : '';
                            window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                        }else {
                            window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.paramsUI.serviceDetailsSelectedText;
                        }
                    }
            }
        }
        
        if(conceptCode == 'MRA'){
            objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel = 'MRA Website';
            let address2 = objOSGlobal.requestBodyValues.Address2 ? ", " + objOSGlobal.requestBodyValues.Address2:"";
            objOSGlobal.requestBodyValues.Address = objOSGlobal.requestBodyValues.Address + address2;
            objOSGlobal.requestBodyValues.ServiceText = "MRA Service Call";
        }

        if(window.objOSGlobal.requestBodyValues.ConceptId == "4"){
            window.objOSGlobal.requestBodyValues.EmailOptOut = true;
        }
        objOSGlobal.requestBodyValues.VendorId = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].vendorId;
        objOSGlobal.requestBodyValues.VendorName = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].vendorName;
        if(window.brandData?.enable_cro_short_form === true){
            croShortForm();
        }
        try{
        if(window.brandData?.add_preffered_method_of_contact ){
            var preferMethodText = sessionStorage.getItem('PreferredCommunicationText');
            if(preferMethodText){
                window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note.concat("\n Preferred Method of Contact: " + preferMethodText.toString() + "\n");
            }
            
        }
    } catch(error) {
      console.log("not found add_preffered_method_of_contact", error);
    }
        objOSGlobal.requestBodyValues.ReferralTypeId = convertNullString(objOSGlobal.requestBodyValues.ReferralTypeId);
        objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = convertNullString(objOSGlobal.requestBodyValues.NeighborlyLeadSourceId);
        objOSGlobal.requestBodyValues.LeadSourceId = convertNullString(objOSGlobal.requestBodyValues.LeadSourceId);
        //US-317398: Populate LeadSourceID in booking message Start
        if($("select[name='howDidHereAboutUs']").val()){
            objOSGlobal.requestBodyValues.LeadSourceId=$("select[name='howDidHereAboutUs']").val();
        }
        else if($('#referenceDetails').val() && $("#brandName").val().toLowerCase() !== "mr-appliance"){
            objOSGlobal.requestBodyValues.LeadSourceId=$('#referenceDetails').val();
        }
        //US-317398: Populate LeadSourceID in booking message End
        let campaignName = sessionStorage.getItem('campaign_name');
        let leadAdditionalNotes = JSON.parse(sessionStorage.getItem('lead_additional_notes'));
        if( window.brandData?.add_preferred_communicationtype_lead_flow && $('.disc-info').length) {
            const notewithNo = " I would like to receive text updates: NO";
            const notewithYes = " I would like to receive text updates: YES";
            if ($("#customControlInline").is(":checked")) {
                objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note ? (objOSGlobal.requestBodyValues.Note +','+ notewithYes): notewithYes;
                objOSGlobal.requestBodyValues.PreferredCommunicationType = "2";
            } else {
                objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note ? (objOSGlobal.requestBodyValues.Note +','+ notewithNo): notewithNo;
            }
        }
        if(objOSGlobal.requestBodyValues.serviceDetailsNote){
            objOSGlobal.requestBodyValues.Note=objOSGlobal.requestBodyValues.Note? objOSGlobal.requestBodyValues.Note+", "+objOSGlobal.requestBodyValues.serviceDetailsNote:objOSGlobal.requestBodyValues.serviceDetailsNote;
            objOSGlobal.requestBodyValues.Comments=objOSGlobal.requestBodyValues.Comments? objOSGlobal.requestBodyValues.Comments+", "+objOSGlobal.requestBodyValues.serviceDetailsNote:objOSGlobal.requestBodyValues.serviceDetailsNote;
        }
        var payload = {
            // "FranchiseId": objOSGlobal.requestBodyValues.FranchiseId ? objOSGlobal.requestBodyValues.FranchiseId : 0,
            "FranchiseId":"",
            "HasSameDayScheduling": objOSGlobal.requestBodyValues.HasSameDayScheduling ? objOSGlobal.requestBodyValues.HasSameDayScheduling : true,
            "IsBetaTester": objOSGlobal.requestBodyValues.IsBetaTester ? objOSGlobal.requestBodyValues.IsBetaTester : false,
            "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
            "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
            "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? Number(objOSGlobal.requestBodyValues.ConceptId) : 0,
            "UseFranchiseProfileOptInForPOSScheduling": objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling ? objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling : true,
            "IsFranchiseOptedIntoPOSScheduling": objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling ? objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling : true,
            "C4ChannelTypeId": objOSGlobal.requestBodyValues.C4ChannelTypeId ? objOSGlobal.requestBodyValues.C4ChannelTypeId : 0,
            "ConceptCalledId": objOSGlobal.requestBodyValues.ConceptCalledId ? Number(objOSGlobal.requestBodyValues.ConceptCalledId) : 0,
            "ConceptName": objOSGlobal.requestBodyValues.ConceptName ? objOSGlobal.requestBodyValues.ConceptName : "",
            "ConceptCode": objOSGlobal.requestBodyValues.ConceptCode ? objOSGlobal.requestBodyValues.ConceptCode : "",
            "C4LeadsEnabled": objOSGlobal.requestBodyValues.C4LeadsEnabled ? objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
            "CanceledJobInformation": objOSGlobal.requestBodyValues.CanceledJobInformation ? objOSGlobal.requestBodyValues.CanceledJobInformation : "",
            "VendorId": objOSGlobal.requestBodyValues.VendorId ? objOSGlobal.requestBodyValues.VendorId : "",
            "NationalLeadId": objOSGlobal.requestBodyValues.NationalLeadId ? objOSGlobal.requestBodyValues.NationalLeadId : "",
            "IsTest": objOSGlobal.requestBodyValues.IsTest,
            "VendorName": objOSGlobal.requestBodyValues.VendorName ? objOSGlobal.requestBodyValues.VendorName : "",
            "WebLocationId": objOSGlobal.requestBodyValues.WebLocationId ? Number(objOSGlobal.requestBodyValues.WebLocationId) : 0,
            "C4CallCenterId": objOSGlobal.requestBodyValues.C4CallCenterId ? objOSGlobal.requestBodyValues.C4CallCenterId : 0,
            "DayTimePhoneNumber": objOSGlobal.requestBodyValues.DayTimePhoneNumber ? objOSGlobal.requestBodyValues.DayTimePhoneNumber : "",
            "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
            "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
            "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
            "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
            "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email.trim() : "",
            "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
            "PhoneType": objOSGlobal.requestBodyValues.PhoneType ? objOSGlobal.requestBodyValues.PhoneType : "",
            "PostalCode": objOSGlobal.requestBodyValues.PostalCode ? objOSGlobal.requestBodyValues.PostalCode : "",
            "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
            "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
            "Country": objOSGlobal.requestBodyValues.Country ? objOSGlobal.requestBodyValues.Country : "",
            "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
            "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
            "LeadSource": objOSGlobal.requestBodyValues.LeadSource ? objOSGlobal.requestBodyValues.LeadSource : "Web",
            "LicenseNumber": objOSGlobal.requestBodyValues.LicenseNumber ? objOSGlobal.requestBodyValues.LicenseNumber : "",
            "WebLocationAccountCode": objOSGlobal.requestBodyValues.WebLocationAccountCode ? objOSGlobal.requestBodyValues.WebLocationAccountCode : "",
            "LeadOrigin": objOSGlobal.requestBodyValues.LeadOrigin ? objOSGlobal.requestBodyValues.LeadOrigin : "Web",
            "CampaignName": campaignName ? campaignName : "N/A",
            "ReferringURL": objOSGlobal.requestBodyValues.ReferringURL,
            "CurrentPage":  objOSGlobal.requestBodyValues.CurrentPage,
            "Disposition": objOSGlobal.requestBodyValues.Disposition ? objOSGlobal.requestBodyValues.Disposition : "",
            "CallType": objOSGlobal.requestBodyValues.CallType ? objOSGlobal.requestBodyValues.CallType : "",
            "PreferredCommunicationType": objOSGlobal.requestBodyValues.PreferredCommunicationType ? objOSGlobal.requestBodyValues.PreferredCommunicationType : "",
            "EmailOptOut": objOSGlobal.requestBodyValues.EmailOptOut,
            "TagName": objOSGlobal.requestBodyValues.TagName ? objOSGlobal.requestBodyValues.TagName : "",
            "Latitude": objOSGlobal.requestBodyValues.Latitude ? objOSGlobal.requestBodyValues.Latitude : 0,
            "Longitude": objOSGlobal.requestBodyValues.Longitude ? objOSGlobal.requestBodyValues.Longitude : 0,
            "LocationType": objOSGlobal.requestBodyValues.LocationType ? objOSGlobal.requestBodyValues.LocationType : "",
            "IsGoogleAddress": objOSGlobal.requestBodyValues.IsGoogleAddress ? objOSGlobal.requestBodyValues.IsGoogleAddress : true,
            "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
            "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
            "ServiceText": objOSGlobal.requestBodyValues.ServiceText ? objOSGlobal.requestBodyValues.ServiceText : "",
            "ServiceID": objOSGlobal.requestBodyValues.ServiceID ? objOSGlobal.requestBodyValues.ServiceID : 0,
            "Warranty": objOSGlobal.requestBodyValues.Warranty ? objOSGlobal.requestBodyValues.Warranty : false,
            "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
            "MakeId": objOSGlobal.requestBodyValues.MakeId ? objOSGlobal.requestBodyValues.MakeId : 0,
            "LeadSourceId": objOSGlobal.requestBodyValues.LeadSourceId ? objOSGlobal.requestBodyValues.LeadSourceId : 0,
            "SecondaryLeadSourceId": objOSGlobal.requestBodyValues.SecondaryLeadSourceId ? objOSGlobal.requestBodyValues.SecondaryLeadSourceId : 0,
            "serviceTypeID" : serviceTypeID,
            "businessName" : window.objOSGlobal.requestBodyValues.BusinessName ? window.objOSGlobal.requestBodyValues.BusinessName : "" ,
            "ServiceTypeProblem": objOSGlobal.requestBodyValues.ServiceTypeProblem ? objOSGlobal.requestBodyValues.ServiceTypeProblem : "",
            "OverrideJobPromisedDate": objOSGlobal.requestBodyValues.OverrideJobPromisedDate ? objOSGlobal.requestBodyValues.OverrideJobPromisedDate : "",
            "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
            "ServiceSkuIds": objOSGlobal.requestBodyValues.ServiceSkuIds,
            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
            "EstimateHouseStyle": objOSGlobal.requestBodyValues.EstimateHouseStyle ? objOSGlobal.requestBodyValues.EstimateHouseStyle : "",
            "EstimateSquareFeet": objOSGlobal.requestBodyValues.EstimateSquareFeet ? objOSGlobal.requestBodyValues.EstimateSquareFeet : "",
            "EstimateBedrooms": objOSGlobal.requestBodyValues.EstimateBedrooms ? objOSGlobal.requestBodyValues.EstimateBedrooms : "",
            "EstimateBathrooms": objOSGlobal.requestBodyValues.EstimateBathrooms ? objOSGlobal.requestBodyValues.EstimateBathrooms : "",
            "EstimatePets": objOSGlobal.requestBodyValues.EstimatePets ? objOSGlobal.requestBodyValues.EstimatePets : "",
            "CCFirstName": objOSGlobal.requestBodyValues.CCFirstName ? objOSGlobal.requestBodyValues.CCFirstName : "",
            "CCLastName": objOSGlobal.requestBodyValues.CCLastName ? objOSGlobal.requestBodyValues.CCLastName : "",
            "CCType": objOSGlobal.requestBodyValues.CCType ? objOSGlobal.requestBodyValues.CCType : "",
            "CCSuffix": objOSGlobal.requestBodyValues.CCSuffix ? objOSGlobal.requestBodyValues.CCSuffix : "",
            "CCToken": objOSGlobal.requestBodyValues.CCToken ? objOSGlobal.requestBodyValues.CCToken : "",
            "CCExpirationDate": objOSGlobal.requestBodyValues.CCExpirationDate ? objOSGlobal.requestBodyValues.CCExpirationDate : "",
            "CCComment": objOSGlobal.requestBodyValues.CCComment ? objOSGlobal.requestBodyValues.CCComment : "",
            "IsLeadOnly": objOSGlobal.requestBodyValues.IsLeadOnly,
            "TimeBlock": {
                "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? objOSGlobal.requestBodyValues.ConceptId : 0,
                "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
                "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
                "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
                "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
                "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
                "JobPromisedDate": objOSGlobal.requestBodyValues.JobPromisedDate ? objOSGlobal.requestBodyValues.JobPromisedDate : "",
                "IsAvailable": objOSGlobal.requestBodyValues.IsAvailable ? objOSGlobal.requestBodyValues.IsAvailable : true,
                "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
                "DisplayText": objOSGlobal.requestBodyValues.DisplayText ? objOSGlobal.requestBodyValues.DisplayText : ""
            },
            "OverrideCSRName": objOSGlobal.requestBodyValues.OverrideCSRName ? objOSGlobal.requestBodyValues.OverrideCSRName : "",
            "OverrideComment": objOSGlobal.requestBodyValues.OverrideComment ? objOSGlobal.requestBodyValues.OverrideComment : "",
            "SyncTenantName": objOSGlobal.requestBodyValues.SyncTenantName ? objOSGlobal.requestBodyValues.SyncTenantName : "",
            "IsEmergencyCall": objOSGlobal.requestBodyValues.IsEmergencyCall ? objOSGlobal.requestBodyValues.IsEmergencyCall : false,
            "CallId": objOSGlobal.requestBodyValues.CallId ? objOSGlobal.requestBodyValues.CallId : "",
            "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
            "CustomerType": objOSGlobal.requestBodyValues.CustomerType ? objOSGlobal.requestBodyValues.CustomerType : "",
            "NeighborlyAccountId": objOSGlobal.requestBodyValues.NeighborlyAccountId ? objOSGlobal.requestBodyValues.NeighborlyAccountId : 0,
            "NeighborlyLeadSourceId": objOSGlobal.requestBodyValues.NeighborlyLeadSourceId,
            "FileUrls": [{
                "FileUrl": fileURL1
            }, {
                "FileUrl": fileURL2
            }, {
                "FileUrl": fileURL3
            }],
            "JobFrequency": objOSGlobal.requestBodyValues.JobFrequency ? objOSGlobal.requestBodyValues.JobFrequency : "",
            "JobFrequencyDetail": objOSGlobal.requestBodyValues.JobFrequencyDetail ? objOSGlobal.requestBodyValues.JobFrequencyDetail : "",
            "Year": objOSGlobal.requestBodyValues.Year ? objOSGlobal.requestBodyValues.Year : 0,
            "Make": objOSGlobal.requestBodyValues.Make ? objOSGlobal.requestBodyValues.Make : "",
            "Model": objOSGlobal.requestBodyValues.Model ? objOSGlobal.requestBodyValues.Model : "",
            "BodyStyle": objOSGlobal.requestBodyValues.BodyStyle ? objOSGlobal.requestBodyValues.BodyStyle : "",
            "VIN": objOSGlobal.requestBodyValues.VIN ? objOSGlobal.requestBodyValues.VIN : "",
            "LicensePlate": objOSGlobal.requestBodyValues.LicensePlate ? objOSGlobal.requestBodyValues.LicensePlate : "",
            "WindshieldRepair": objOSGlobal.requestBodyValues.WindshieldRepair ? objOSGlobal.requestBodyValues.WindshieldRepair : "",
            "HeadlightRestoration": objOSGlobal.requestBodyValues.HeadlightRestoration ? objOSGlobal.requestBodyValues.HeadlightRestoration : "",
            "ServiceRequested": [] as any[],
            "LeadAdditionalNotes":leadAdditionalNotes?leadAdditionalNotes: {
                "LeadAdditionalNoteLabel": objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel ? objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel : "Website",
                "LeadAdditionalNoteText": objOSGlobal.requestBodyValues.LeadAdditionalNoteText ? objOSGlobal.requestBodyValues.LeadAdditionalNoteText : ""
            },
            "FormOfPayment": objOSGlobal.requestBodyValues.FormOfPayment ? objOSGlobal.requestBodyValues.FormOfPayment : "",
            "InsuranceCompany": objOSGlobal.requestBodyValues.InsuranceCompany ? objOSGlobal.requestBodyValues.InsuranceCompany : "",
            "DeductibleAmount": objOSGlobal.requestBodyValues.DeductibleAmount ? objOSGlobal.requestBodyValues.DeductibleAmount : 0,
            "PolicyNumber": objOSGlobal.requestBodyValues.PolicyNumber ? objOSGlobal.requestBodyValues.PolicyNumber : "",
            "ScheduleID": objOSGlobal.requestBodyValues.ScheduleID ? objOSGlobal.requestBodyValues.ScheduleID : "",
            // "message": objOSGlobal.requestBodyValues.MessageField ? objOSGlobal.requestBodyValues.MessageField : "",
            "SignUpForUpdates":window.objOSGlobal.requestBodyValues.SignUpForUpdates,
            "ReferralType": window.objOSGlobal.requestBodyValues.ReferralType ? window.objOSGlobal.requestBodyValues.ReferralType : "",
            "ReferralTypeId": window.objOSGlobal.requestBodyValues.ReferralTypeId ? window.objOSGlobal.requestBodyValues.ReferralTypeId : ""
        };
        let bookingApiKey = window.DEFINE_VALUE?.JS_API_KEY;
        if($("#conceptCode").val().toUpperCase()==='MRR' && window.DEFINE_VALUE?.JS_Booking_API_KEY){
            bookingApiKey = window.DEFINE_VALUE.JS_Booking_API_KEY;
        }
        let formdata = JSON.stringify(payload)
        let config = {
            method: 'post',
            url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + bookingApiKey,
            headers: {
                'Content-Type': 'application/json'
            },
            data: formdata
        };
        if (conceptCode === "FSP" && objOSGlobal.requestBodyValues.ScheduleID === '')
            {
                const osPayload = {
                    "FranchiseWebLocationId": objOSGlobal.requestBodyValues.WebLocationId ? Number(objOSGlobal.requestBodyValues.WebLocationId) : 0,
                    "ConceptId": objOSGlobal.requestBodyValues.ConceptId,
                    "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
                    "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
                    "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
                    "ZipCode": objOSGlobal.requestBodyValues.PostalCode ? objOSGlobal.requestBodyValues.PostalCode : "",
                    "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email.trim() : "",
                    "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
                    "IsNewCustomer": objOSGlobal.requestBodyValues.CustomerType,
                    "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
                    "SignUpForUpdates": objOSGlobal.requestBodyValues.SignUpUpdates,
                    "IsTest": objOSGlobal.requestBodyValues.IsTest,
                    "VendorId": objOSGlobal.requestBodyValues.VendorId,
                    "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
                    "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
                    "Country": objOSGlobal.requestBodyValues.Country ? objOSGlobal.requestBodyValues.Country : "",
                    "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
                    "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
                    "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
                    "PreferredCommunicationType": objOSGlobal.requestBodyValues.PreferredCommunicationType ? objOSGlobal.requestBodyValues.PreferredCommunicationType : "",
                    "FileUrls": [{
                            "FileUrl": fileURL1
                            }, {
                                "FileUrl": fileURL2
                            }, {
                                "FileUrl": fileURL3
                            }],
                    };
                const osFormdata = JSON.stringify(osPayload)
                let config = {
                    method: 'post',
                    url: apiConfig.contactUSApi + '?apikey=' + window.DEFINE_VALUE.JS_API_KEY,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: osFormdata
                };
                axios(config)
                    .then(function (data)
                    {
                        stopLoader();
                        let RLPostessionData = RLPostData(payload);
                        sessionStorage.setItem('rl-post-data', JSON.stringify(RLPostessionData));
                        setConfirmationFieldValue(payload);
                        let domainName = window.location.origin.toLowerCase();
                        let websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() : domainName;
                        let trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
                        {
                            if (websiteurl.includes('confirmation'))
                            {
                                window.location.replace(websiteurl + trailingSlash);
                            } else
                            {
                                window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
                            }
                        }
                        let apiData = '{ "Result": ' + JSON.stringify(data) + ', "Form data": ' + formdata + ', "Response message":"SUCCESS" }';
                        sessionStorage.setItem('NEI-API data', apiData);
    
                    }).catch(function (error)
                    {
                        stopLoader();
                        $('.result-stack-logo').show();
                        $('.progressbar, .progress').hide();
                        $(".tab-pane.fieldset").removeClass('active');
                        $('#steptwo').addClass('active');
                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                        $('#steptwo .head-title.primary-theme-clr').html(Api_Error_Msg);
                        $(".localized-progress").hide();
                    });
            }else{
                axios(config)
                .then(function (data)
                {
                    stopLoader();
                    let RLPostessionData = RLPostData(payload);
                    sessionStorage.setItem('rl-post-data',JSON.stringify(RLPostessionData));
                    if(sessionStorage.getItem("isSchedulerProEnabled")==='true'){
                        setServiceDataConfirmationText();
                    }
                    let brandName = $('#brandName').val();
                    setConfirmationFieldValue(payload);
                    if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === "my-handyman") {
                    sessionStorage.setItem('CServiceType', window.objOSGlobal.requestBodyValues.MrhServiceType); 
                    sessionStorage.setItem('CBusinessName',window.objOSGlobal.requestBodyValues.BusinessName);
                    }
                    if (conceptCode == 'GUY') {
                    sessionStorage.setItem('guyServiceType',  window.objOSGlobal.requestBodyValues.guyServiceType); 
                    sessionStorage.setItem('guyServiceRequest',window.objOSGlobal.requestBodyValues.guyServiceRequest);
                    }
                        let trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
                        localStorage.removeItem("addressMly");
                        if (conceptCode == 'MLY')
                        {
                            let domainName = window.location.origin.toLowerCase();
                            let websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() : domainName;
                            if (window.objOSGlobal.requestBodyValues.requestContactForm)
                            {
                                window.location.replace(websiteurl + "/" + "financing-thank-you");
                            }
                            else if (window.objOSGlobal.requestBodyValues.callback)
                            {
                               
                                if(websiteurl.includes('thank-you')){
                                    window.location.replace(websiteurl+ trailingSlash);
                                }else{
                                    window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
                                }
                            } else
                            {
                                if (brandName == "protect-painters")
                                {
                                    websiteurl = websiteurl + "/" + brandName;
                                }
                                if(websiteurl.includes('confirmation')){
                                    window.location.replace(websiteurl+ trailingSlash);
                                }else{
                                    window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
                                }
                            }
                        } else
                        {
                            
                            let domainName = window.location.origin.toLowerCase();
                            var websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() : domainName;
                            if (window.objOSGlobal.requestBodyValues.requestContactForm)
                            {
                                window.location.replace(websiteurl + "/" + "financing-thank-you");
                            }else{
                                if($("#conceptCode").val() === "MRR" && window.location.href.includes('/gatineau-francais/')){
                                    websiteurl = websiteurl + "/gatineau-francais";
                                }
                                if (brandName == "protect-painters")
                                {
                                    websiteurl = websiteurl + "/" + brandName;
                                }
                                
                                if(websiteurl.includes('confirmation')){
                                    window.location.replace(websiteurl+ trailingSlash);
                                }else{
                                    window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
                                }
                            }
                        }
                    
                        let apiData = '{ "Result": '+ JSON.stringify(data) +', "Form data": '+ formdata +', "Response message":"SUCCESS" }';
                        sessionStorage.setItem('NEI-API data',apiData);
                })
                .catch(function (error)
                {
                    stopLoader();
                    animation.modalFlag = true;
                    $('.result-stack-logo').show();
                    $('.progressbar, .progress').hide();
                    $(".tab-pane.fieldset").removeClass('active');
                    $('#steptwo').addClass('active');
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('#steptwo .head-title.primary-theme-clr').html(Api_Error_Msg);
                    if (conceptCode.toLowerCase() !== 'mly'){
                        $('.schedulerModalLabel').addClass('d-none');
                        $('.schedulerModalLabel').empty();
                    }
                    $('.modal').on('hidden.bs.modal', function ()
                    {
                        if (animation.modalFlag)
                        {
                            location.reload();
                        }
                    });
                    $(".localized-progress").hide();
                });
            }
        
    }
}

function convertNullString(inputVal:any){
    if(inputVal=="null"){
        inputVal = "";
    }
    return inputVal;
}

function setConfirmationFieldValue(payload:any){
    localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
    localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
    let serviceName = window.objOSGlobal.requestBodyValues.serviceName;
    sessionStorage.setItem('CFirstName', payload.FirstName);
    sessionStorage.setItem('CLastName', payload.LastName);
    sessionStorage.setItem('CAddress', payload.Address);
    sessionStorage.setItem('CAddress2', payload.Address2);
    sessionStorage.setItem('CCity', payload.City);
    sessionStorage.setItem('CState', payload.State);
    sessionStorage.setItem('CPostalCode', payload.PostalCode);
    sessionStorage.setItem('CJobDate', payload.TimeBlock.JobPromisedDate);
    sessionStorage.setItem('CEmail', payload.Email);
    sessionStorage.setItem('CPhone', payload.Phone);
    sessionStorage.setItem('CLeadOnly', payload.IsLeadOnly);
    sessionStorage.setItem('CServiceRequest', serviceName);
}