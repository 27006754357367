// import { addMainmenuClickEvent } from "./add-mainmenu-click-event";
import { searchServiceInitial } from "../search-service/search-service-initial";
import { dynamicGetMenu } from "./dynamic-get-menu";
import { filterByValue } from "./filter-by-value";
import { footertServiceModifierMenu } from "./footer-menu";
import { enableImageObj } from "./global-variable";
import { menuGenrator } from "./menu-genrator";
import { rootArrayMenu } from "./root-array-menu";
import { serviceProvideFunction } from "./service-provide";
import { sideMenuCategoryWrap } from "./side-menu-category-wrap";
import { loadAtStart } from "../scheduler/required-Items-on-Load";


export function headerDynamicLocalMenu(){
    let localMenu:any;
    let brandMenu:any;
    let categoryMenu:any;
    let services:any;
    let localStorageDbaName:any = localStorage.getItem('dbaname');
    let localStorageStateCode:any = localStorage.getItem('stateCode');

    let countryCode:any = $('#assetCountryCode').length ? $('#assetCountryCode').val() : $('#countryCode').val();
    let localWeblocationId:any = $('#weblocationId').val();
    let brandName = $('#brandName').val();
    let dbaName:any = $('#dbaName').val();
    dbaName  = dbaName?.replace(' ','-');
    let stateCode:any = $("#stateCode").val() || (window.objOSGlobal.requestBodyValues.localStateCode && window.objOSGlobal.requestBodyValues.localStateCode.toLowerCase());
    if(dbaName && stateCode){
        dbaName = dbaName;
        localStorage.setItem('dbaname',dbaName);
        localStorage.setItem('stateCode',stateCode);
        localStorage.setItem('localWebLocationId',localWeblocationId);
    }else{
        dbaName = localStorageDbaName;
        stateCode = localStorageStateCode || (window.objOSGlobal.requestBodyValues.localStateCode && window.objOSGlobal.requestBodyValues.localStateCode.toLowerCase());
    }
    if(!dbaName){
        dbaName = new URL(window.objOSGlobal.requestBodyValues.locationWebsiteUrl)
        dbaName = dbaName?.pathname?.replaceAll('/','');
    }
    loadAtStart();
    let localNavigationUrl:any;
    if($("#conceptCode").val() === "MRR" && window.location.href.includes('/gatineau-francais/')) {
        localNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/_assets/${brandName}/${stateCode}/${dbaName}/gatineau-francais/js/data/dynamic_menu.json`;
    }else {
        localNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/_assets/${brandName}/${stateCode}/${dbaName}/js/data/dynamic_menu.json`;
    }
    let brandNavigationUrl = `${window.location.origin}/${countryCode}/en-${countryCode}/${brandName}/_assets/js/data/dynamic_menu.json`;
    dynamicGetMenu(brandNavigationUrl,function(brandResult:any){
            if(brandResult){
                let brandData =  brandResult.serviceMenu;
                let brandOptions =  brandData["country"][0].brand[0].option;
                brandMenu = brandOptions;
            }
            dynamicGetMenu(localNavigationUrl,function(localResult:any){
                if(localResult){
                    let menuLength:any;
                    let brandCategories:any;
                    let localCategories:any;
                    let brndCat:any;
                    let localData =  localResult.serviceMenu;
                    let localOptions =  localData["country"][0].brand[0].webLocation[0].option;
                    enableImageObj.enableImage = localData["country"][0].brand[0].enableImages;
                    localMenu = localOptions;
                    if(brandMenu?.length > localMenu?.length){
                        menuLength = localMenu.length;
                    }else{
                        menuLength = brandMenu.length;
                    }
                    for(let i=0;i<menuLength;i++){
                        if(brandMenu[i].name == localMenu[i].name){
                            if(localMenu[i].category.length == 0 ){
                                brandCategories = brandMenu[i].services;
                                localCategories = localMenu[i].services;
                                brndCat = brandCategories?.map((ser:any)=>{
                                return ser.name;
                                });
                            }else{
                                brandCategories = brandMenu[i].category;
                                localCategories = localMenu[i].category;
                                brndCat = brandCategories?.map((cat:any)=>{
                                return cat.name;
                                });
                            }
                            localCategories.sort((a:any,b:any)=>{
                                let aIndex= brndCat.indexOf(a.name);
                                let bIndex = brndCat.indexOf(b.name);
                                if(aIndex == -1){
                                return 1
                                }
                                if(bIndex ==-1){
                                    return -1
                                }
                                if(aIndex<bIndex){
                                    return -1
                                }
                                if(aIndex>bIndex){
                                    return 1
                                }
                                return 0
                            })
                            
                        }
                    }
                    let fileterLocalMenu = filterByValue(localMenu);
                    footertServiceModifierMenu(fileterLocalMenu);
                    searchServiceInitial();
                    let rootMenus:any = [];
                    for(let i = 0;i<fileterLocalMenu.length;i++){
                        categoryMenu = fileterLocalMenu[i].category;
                        services = fileterLocalMenu[i].services || [];
                        if(services.length) {
                            categoryMenu = services;
                        }
                        rootArrayMenu(fileterLocalMenu[i],rootMenus);
                    }
                    $('.dropdown.menu-large.service-menu').remove();
                    $('.main-nav .nav-section .nav.navbar-nav').prepend(rootMenus);
                    for(let i = 0;i<fileterLocalMenu.length;i++){
                        categoryMenu = fileterLocalMenu[i].category;
                        services = fileterLocalMenu[i].services || [];
                        if(services.length) {
                            categoryMenu = services;
                        }
                        if(categoryMenu.length !== 0 ) {
                        menuGenrator(categoryMenu, fileterLocalMenu[i]);
                        }
                    } 
                    sideMenuCategoryWrap(fileterLocalMenu);
                    if(!fileterLocalMenu.length){
                        for(let i = 0;i<localMenu.length;i++){
                            categoryMenu = localMenu[i].category;
                            services = localMenu[i].services || [];
                            if(services.length) {
                                categoryMenu = services;
                            }
                            menuGenrator(categoryMenu, localMenu[i]);
                        }
                        sideMenuCategoryWrap(localMenu);
                    }
                    if($('#services').hasClass('services-list-wrap')){
                        serviceProvideFunction(localMenu);
                    }
                    //addHastoMobileurl();
                }
                // addMainmenuClickEvent();
                
            });
    });
}