import { $ } from '../third-party-imports';
import { apiConfig } from "../config";
import { getBoundingBox, getDistanceFromLatLonInKm } from "./get-bounding-box";
import { getFullAttributeApi } from './getFullAttributeApi';
import { removePreFilledData } from '../scheduler/common-os/remove-prefilled-data';
import { commonProgressBar } from '../scheduler/common-os/common-progress-bar';
import { getStateFun } from './getStateFun';
import { getBrandJsonValue } from './get-brands-json';
import { stopLoader } from './stopLoader';
import { guy_OptOsFlow } from '../scheduler/guy-os-flow/guy-opt-os-flow';
import { guyPreFilledData } from '../scheduler/guy-os-flow/guy-pre-filldata-remove';
import { mraDefaultCall } from '../scheduler/mra-os-flow/mra-os-flow';
import { findMyLocationRedirect, mrh_OptOsFlow } from '..';
import {add_custEv_LocalDataLayer, getLocalization} from '../util/util';
import { asvPreFilledData } from '../scheduler/asv-os-flow/asv-pre-filldata-remove';
import { asvFormValidation } from '../scheduler/asv-os-flow/asv-form-validation';

// define local constants for frequently used functions
const asin = Math.asin
const cos = Math.cos
const sin = Math.sin
const PI_180 = Math.PI / 180

let checkCroleadEnable:any;
let checkCroleadEnableLocation:any;
let brandUrlName:any;
let nextId:any = $('.service-search-screen').next().attr("id");
const country = $("#countryCode").val().toLowerCase();
const brandCodeName = $('#conceptCode').val().toLowerCase();
let brandName:any;
let range:any;
let rangeType:any;
export async function nearBySearchLocation(searchQuery: any)
{
    $('.progress,.progressbar').hide();
    let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
    brandUrlName = brandJsonItems[0]?.brandUrlName;
    brandName = brandJsonItems[0]?.name;
    checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    checkCroleadEnableLocation = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match_findLocation || false ;
    range = brandJsonItems[0]?.nearby_location_range || 50;
    rangeType = brandJsonItems[0]?.nearby_location_range_type || "miles";
    const factor = 0.621371
    const data = await searchLocationNearBy(searchQuery);
    if (data?.items?.length)
    {
        let result = await getDataFromApi();
        let bbox:any;
        const position = data.items[0].position;
        let rangeConvert: any = range;
        sessionStorage.setItem('distanceTo',JSON.stringify(position));
        if(rangeType ==="km"){
            rangeConvert = Math.round(range * factor);
        }
        bbox = getBoundingBox(position.lat, position.lng,rangeConvert);
        
        bbox = new H.geo.Rect(bbox[0], bbox[1], bbox[2], bbox[3]);
        filterResults(bbox, result, -1);
    } else
    {
        stopLoader();
        $('.tab-pane.fieldset').removeClass('active');
        $('.no-service-screen').addClass('active');
        if(checkCroleadEnable || checkCroleadEnableLocation){
                if(window.objOSGlobal.requestBodyValues.isFindYourLocation || checkCroleadEnableLocation){
                    $('#js-schedulerDialog').modal('show');
                }
            if(country ==='ca'){
                $('.modal-header-two').addClass('d-none');
                $('.modal-header-one').removeClass('d-none');
                let contactPage ="/contact-us";
                if(brandCodeName==="guy" || brandCodeName==="mrr"){
                    if(brandCodeName==="guy"){
                        contactPage = "/contact"
                    }
                    else if(brandCodeName==="mrr"){
                        contactPage="/about/contact-us/"
                    }
                    $('.no-service-screen .head-title').html(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType ==="km" ?"kilometers" : "miles" } that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="${contactPage}">click here</a> to complete your request form  and a ${brandName} representative will contact you as soon as possible.`);
                }
                else if(brandUrlName?.toLowerCase()==="mr-handyman"){
                    $('.no-service-screen .head-title').text(`We’re sorry. We could not find a location within ${range} kilometers that services ${window.objOSGlobal.requestBodyValues.PostalCode}`);
                }else{
                    $('.no-service-screen .head-title').html(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType ==="km" ?"kilometers" : "miles" } that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="${contactPage}">click here</a> to complete your request form  and a ${brandName} representative will contact you as soon as possible.`);
                }
                
            }
            else if (brandCodeName === "guy") {
                $('.no-service-screen .head-title').html(`Thank You for your interest in ${brandName}! Your ZIP code is outside of our standard service area. Please <a href ="/about/contact-us/" style="font-weight: 600">click here to complete our request form</a> and a representative from ${brandName} will contact you as soon as possible.`);
            }
            else {
                $('.no-service-screen .head-title').html(`We’re sorry. We could not find a location within ${range} miles that services  ${window.objOSGlobal.requestBodyValues.PostalCode}`);
            }
        }
        else{
            if(country ==='ca'){
                $('.no-service-screen .cust-opus-h2-cls').html(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType ==="km" ?"kilometers" : "miles" } that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="/about/contact-us/">click here </a> to complete your request form and a ${brandName} representative will contact you as soon as possible.`);
            }else{
                $('.no-service-screen .cust-opus-h2-cls').text(`We’re sorry. We could not find a location within 50 miles that services  ${window.objOSGlobal.requestBodyValues.PostalCode}`);
            }
        }
    }
}


const getDataFromApi = async () =>
{
    
    const franchiseGroup = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ?   window.DEFINE_VALUE.JS_FranchiseGroup_URL : 'franchisegroup-api';
    const domainName = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? window.DEFINE_VALUE.JS_API_URL : 'https://api.neighborly.com';
    const apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ?   window.DEFINE_VALUE.JS_API_KEY : 'pneiweb-9pbaOywNKQj853D';
    let getConceptCode = $('#conceptCode').val();
    const response = await fetch(`${domainName}/${franchiseGroup}/v1/public/concepts/${getConceptCode}/franchiseweblocations?apikey=${apiKey}`);
    const json = await response.json();
    let filterlocation = filterData(json);
    return filterlocation;
}


const filterData = (data: any) =>
{
    let details = data.flatMap((f: any) => f.franchiseDetails);
     if(country ==='us'){
        details = details.filter((c: any) => c.country === 'USA' ||  c.country === 'United States');
     }else{
        details = details.filter((c: any) => c.country === 'CAN' || c.country === 'Canada');
     }
   
    let result = [];
    // remove duplicates
    const set = new Set();
    for (let detail of details)
    {
        if (!set.has(detail.franchiseWebLocationId))
        {
            result.push(detail);
            set.add(detail.franchiseWebLocationId);
        }
    }
    return result;
}

const searchLocationNearBy = async function (searchQuery: String)
{
    searchQuery = searchQuery.trim();
    window.objOSGlobal.requestBodyValues.PostalCode = searchQuery;
    const geoCountry = $("#countryCode").val().toLowerCase()==="us" ? "USA" : "CAN";
    const apiUrl = `${apiConfig.geoCodeSearchApiUrl}?apiKey=${window.DEFINE_VALUE.JS_Heremap_API_KEY}&q=${searchQuery}&in=countryCode:${geoCountry}`;
    const res = await fetch(apiUrl)
        .then(data => data.json());
    return res;
}

const filterResults = (bbox: any, franchiseDetails: any, pinnedWeblocationId: any) =>
{
    const distanceTo:any =  JSON.parse(sessionStorage.getItem('distanceTo'));
    let currFranchiseDetails = [];
    for (let i = 0; i < franchiseDetails.length; i++)
    {
        if (bbox.containsLatLng(franchiseDetails[i].latitude, franchiseDetails[i].longitude) ||
            pinnedWeblocationId === franchiseDetails[i].franchiseWebLocationId)
        {
            let distance = getDistanceFromLatLonInKm(distanceTo.lat,distanceTo.lng,franchiseDetails[i].latitude, franchiseDetails[i].longitude,rangeType);
            franchiseDetails[i].distance = distance;
            currFranchiseDetails.push(franchiseDetails[i]);
        }
    }

    if (currFranchiseDetails.length !== 0)
    {
        getStateFun();
        $(".nearby-location-lists ul").empty();
        if (currFranchiseDetails.length === 1)
        {
            let phone = currFranchiseDetails[0].nonPaidTrackingPhone.length !== 0 ? currFranchiseDetails[0].nonPaidTrackingPhone[0] : currFranchiseDetails[0].websiteTrackingPhone[0];
            let dbaName = currFranchiseDetails[0].doingBusinessAs
            let webLocationId = currFranchiseDetails[0].franchiseWebLocationId
            let servcePostalCode = currFranchiseDetails[0].zipCode;
            if(phone){
                if(phone){
                    phone = phone ? phone.toString().match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                    phone = "(" + phone[1] + ") " + phone[2] + "-" + phone[3];
                }
                window.objOSGlobal.paramsUI.brandPhoneNumber = phone;
            }
            window.objOSGlobal.paramsUI.doingBusinessAs = dbaName;
            localStorage.setItem('zipcode', window.objOSGlobal.requestBodyValues.PostalCode);
            window.objOSGlobal.requestBodyValues.fsaLead =  true;
            if(brandUrlName==="mr-appliance"){
                window.objOSGlobal.requestBodyValues.servicePostalCode = servcePostalCode;
            }
            if(window.objOSGlobal.requestBodyValues.isFindYourLocation){
                findMyLocationRedirect(currFranchiseDetails?.[0]?.websiteUrl);
            }else{
                redirectoOptimizeFlow(webLocationId);
            }
        } else
        {
            if(window.objOSGlobal.requestBodyValues.isFindYourLocation){
                stopLoader();
                let locationPage = 'locations';
                const originUrl = window.location.origin;
                if(brandUrlName?.toLowerCase() === "mr-handyman"){
                    locationPage = 'local-handyman-service';
                }
                window.location.href = `${originUrl}/${locationPage}`;
                return;
            }
            $('.tab-pane.fieldset').removeClass('active');
            window.objOSGlobal.requestBodyValues.fsaLead = false;
            if(checkCroleadEnable){
                $('.services-links-cont .results-info').text(`${currFranchiseDetails.length} results for ${window.objOSGlobal.requestBodyValues.PostalCode}`);
            }else{
                $('.custom-radio-design label.radio-txt').text(`${currFranchiseDetails.length} locations found`);
            }
            if(checkCroleadEnable){
                $('.modal-header-two').addClass('d-none');
                $('.modal-header-one').removeClass('d-none');
            }
            $('#step-location').addClass('active');
            sortingLocationDistance(currFranchiseDetails);
            generatelocationUl(currFranchiseDetails);
            
        }
    } else
    {
        stopLoader();
        $('.tab-pane.fieldset').removeClass('active');
        $('.no-service-screen').addClass('active');
        if(checkCroleadEnable){
            $('.modal-header-two').addClass('d-none');
            $('.modal-header-one').removeClass('d-none');
            if(country ==='ca'){
                if(window.objOSGlobal.requestBodyValues.isFindYourLocation){
                    $('#js-schedulerDialog').modal('show');
                }
                let contactPage = "/contact-us";
                if (brandCodeName === "guy" || brandCodeName === "mrr")
                {
                    if (brandCodeName === "guy")
                    {
                        contactPage = "/contact"
                    }
                    else if (brandCodeName === "mrr")
                    {
                        contactPage = "/about/contact-us/"
                    }
                    $('.no-service-screen .head-title').html(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType === "km" ? "kilometers" : "miles"} that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="${contactPage}">click here</a> to complete your request form  and a ${brandName} representative will contact you as soon as possible.`);
                }
                else if (brandUrlName?.toLowerCase() === "mr-handyman")
                {
                    $('.no-service-screen .head-title').text(`We’re sorry. We could not find a location within ${range} kilometers that services ${window.objOSGlobal.requestBodyValues.PostalCode}`);
                } else
                {
                    $('.no-service-screen .head-title').html(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType === "km" ? "kilometers" : "miles"} that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="${contactPage}">click here</a> to complete your request form  and a ${brandName} representative will contact you as soon as possible.`);
                }
            } 
            else if (brandCodeName === "guy") {
                $('.no-service-screen .head-title').html(`Thank You for your interest in ${brandName}! Your ZIP code is outside of our standard service area. Please <a href ="/about/contact-us/">click here to complete our request form</a> and a representative from ${brandName} will contact you as soon as possible.`);
                } else{
                $('.no-service-screen .head-title').html(`We’re sorry. We could not find a location within ${range} miles that services  ${window.objOSGlobal.requestBodyValues.PostalCode}`);
            }
        }else{
            if(country ==='ca'){
                $('.no-service-screen .cust-opus-h2-cls').text(`Thank You for your interest in the ${brandName}! We could not find a location within ${range} ${rangeType ==="km" ?"kilometers" : "miles" } that services ${window.objOSGlobal.requestBodyValues.PostalCode}. Please <a href ="/about/contact-us/">click here</a> to complete your request form and a ${brandName} representative will contact you as soon as possible.`);
            }else{
                $('.no-service-screen .cust-opus-h2-cls').text(`We’re sorry. We could not find a location within 50 miles that services  ${window.objOSGlobal.requestBodyValues.PostalCode}`);
            }
        }
    }
}

function generatelocationUl(currFranchiseDetails: any)
{
    let list: any = [];
    currFranchiseDetails.forEach((element: any) =>
    {
        list.push(generatelocationList(element))
    });
    if(checkCroleadEnable){
        $(".location-list ul").html(list.join(''));
    }else{
        $(".nearby-location-lists ul").html(list.join(''));
    }
}
function generatelocationList(franchiseDetails: any)
{
    let phoneNumber = franchiseDetails.nonPaidTrackingPhone.length !== 0 ? franchiseDetails.nonPaidTrackingPhone[0] : franchiseDetails.websiteTrackingPhone[0];
    if(phoneNumber){
        phoneNumber = phoneNumber ? phoneNumber.toString().match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
        phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
    }
    if(checkCroleadEnable){
        const brandName = $('#brandName').val();
        let buttonText = (brandCodeName === "asv") ? "Schedule Service":"Request Estimate";
        return `<li>  
        <div class="locaion-info"> 
            <div class="locaion-time text-center"> <img src="/us/en-us/${brandName}/_assets/images/location-icon2.svg" alt="locaion-icon">${Math.round(franchiseDetails.distance)} ${rangeType ==='km' ? 'km' : 'mi'}. </div>
            <div class="locaion-name"> <h6> ${franchiseDetails.doingBusinessAs} </h6><h6><a href="tel:2144314894"><u>${phoneNumber}</u></a></h6></div>  
        </div>
        <div class="locaion-bttn">
            <a href="#" data-phone="${phoneNumber}"data-dba="${franchiseDetails.doingBusinessAs}"data-webLocationId="${franchiseDetails.franchiseWebLocationId}" data-zipcode="${franchiseDetails.zipCode}" data-websiteUrl = "${franchiseDetails.websiteUrl}" class="scheduler-btn primary-btn gg-primary-btn brand-btn">${buttonText}<img class="ml-2" src="/us/en-us/brand/_assets/images/right-arrow.svg"></a> 
        </div>  
    </li>`;
    } else
    {
        return `<li>
        <span>${franchiseDetails.doingBusinessAs}<br>
            <a class="mrr-primary-red" href="tel:${phoneNumber}">
                <u>${phoneNumber}</u>
            </a>
        </span>
        <button type="button" data-phone="${phoneNumber}"data-dba="${franchiseDetails.doingBusinessAs}"data-webLocationId="${franchiseDetails.franchiseWebLocationId}" data-zipcode="${franchiseDetails.zipCode}" data-websiteUrl = "${franchiseDetails.websiteUrl}" class="mr-primary-btn next near-request-btn"> Request Job Estimate <img width="12px" height="12px" src="/us/en-us/brand/_assets/images/right-arrow-white.svg">
        </button>
    </li>`;
    }
}

$(document).on('click', '.near-request-btn', function (this: any)
{
    const that = this;
    noZiplocationRequestEstimate(that);
});

async function redirectoOptimizeFlow(webLocationId: any)
{
    window.objOSGlobal.requestBodyValues.modifier = 'Residential';
    window.objOSGlobal.requestBodyValues.serviceType = '4';
    window.objOSGlobal.requestBodyValues.WebLocationId = webLocationId;
    localStorage.setItem('estwebLocationId', webLocationId);
    await getBrandJsonValue();
    getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId, () =>
    {
        const pageType = getLocalization()
        if(pageType === "National"){
           const event_found =  dataLayer?.filter((item:any)=> item?.event === "custEv_LocalDataLayerOnLoad");
           if(event_found){
            const get_Index = dataLayer?.findIndex((item:any)=> item?.event === "custEv_LocalDataLayerOnLoad");
            if (get_Index !== -1) {
                dataLayer.splice(get_Index, 1);
              }
           }
            add_custEv_LocalDataLayer();
        }
        if(!window.objOSGlobal.requestBodyValues.fsaLead){
            window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
        }else{
           if(country==="ca"){
              let properPostalCode =  window.objOSGlobal.requestBodyValues.PostalCode?.replace(/ +/g, "");
              if(properPostalCode?.length !== 6){
                  window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
              }
           }
        }
        $('.progressbar').show();
        if(checkCroleadEnable){
            $('.modal-header-two').removeClass('d-none');
            $('.modal-header-one').addClass('d-none');
            if(brandUrlName.toLowerCase()==="grounds-guys"){
                guy_OptOsFlow();
                guyPreFilledData(nextId);
            }
            else if(brandUrlName.toLowerCase()==="mr-appliance"){
                $('.progressbar').css("display","flex");
                mraDefaultCall(nextId);
            }
            else if(brandUrlName.toLowerCase()==="mr-handyman"){
                stopLoader();
                mrh_OptOsFlow(nextId);
            }
            else if(brandUrlName.toLowerCase()==="aire-serv"){
                stopLoader();
                asvPreFilledData(nextId);
                asvFormValidation();
            }
        }else{
            removePreFilledData();
            commonProgressBar();
        } 
    });
}


export function hereMapScriptLoad()
{
    const attatch = (url: any, options: any = {}) =>
    {
        let scriptUrl = $(`script[src*="${url}"]`);
        if(scriptUrl.length === 0 ){
            var elem = document.getElementById(options.id);
            if (!elem)
            {
                const element = document.createElement('script');
    
                element.async = options.async || false;
                element.src = url;
                if (options.id)
                {
                    element.id = options.id;
                }
                document.head.appendChild(element);
            }
        }
    };
    attatch('https://js.api.here.com/v3/3.1/mapsjs-core.js', { id: 'mapsjs-core-js', async: false });
    attatch('https://js.api.here.com/v3/3.1/mapsjs-ui.js', { id: 'mapsjs-ui-js', async: false });
}


function hav(x:any) {
  const s = sin(x / 2)
  return s * s
}

function sortingLocationDistance(allLocation:any){
    const distanceTo = JSON.parse(sessionStorage.getItem('distanceTo'));
    allLocation.sort((a:any, b:any) => relativeHaversineDistance(a.latitude, a.longitude, distanceTo.lat, distanceTo.lng) - relativeHaversineDistance(b.latitude, b.longitude, distanceTo.lat, distanceTo.lng));
}

function relativeHaversineDistance(lat1:any, lon1:any, lat2:any, lon2:any) {
    const aLatRad = lat1 * PI_180
    const bLatRad = lat2 * PI_180
    const aLngRad = lon1 * PI_180
    const bLngRad = lon2 * PI_180
  
    const ht = hav(bLatRad - aLatRad) + cos(aLatRad) * cos(bLatRad) * hav(bLngRad - aLngRad)
    // since we're only interested in relative differences,
    // there is no need to multiply by earth radius or to sqrt the squared differences
    return asin(ht)
  }

$(document).on('click', '.locaion-bttn a', function (this: any){
    const that = this;
    noZiplocationRequestEstimate(that);
    
});

function noZiplocationRequestEstimate(that:any){
    let phone = $(that).attr('data-phone');
    let dbaName = $(that).attr('data-dba');
    let servcePostalCode = $(that).attr('data-zipcode');
    let webLocationId = $(that).attr('data-webLocationId');
    window.objOSGlobal.paramsUI.brandPhoneNumber = phone;
    window.objOSGlobal.paramsUI.doingBusinessAs = dbaName;
    if(brandUrlName==="mr-appliance"){
        window.objOSGlobal.requestBodyValues.servicePostalCode = servcePostalCode;
    }
    localStorage.setItem('zipcode', window.objOSGlobal.requestBodyValues.PostalCode);
    redirectoOptimizeFlow(webLocationId);
}